import React from 'react';
import Lottie from "react-lottie";
import './App.css';
import animationData from './animationData.json';


function App() {
  return (
    <div className="App">
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: -50
        }}>
            <Lottie options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
            }} width={400} height={400} />
            <p style={{
                fontStyle: "normal",
                fontWeight: 500,
                letterSpacing: "0.1em",
                fontSize: "50px",
                marginTop: -30,
                marginBottom: 20,
                marginLeft: 30
            }}>현재 <span style={{fontWeight: 800}}>페이지 준비중</span> 입니다.</p>
            <p style={{
                fontStyle: "normal",
                fontWeight: 500,
                letterSpacing: "0.1em",
                fontSize: "25px",
                marginLeft: 30
            }}>보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.</p>
            <p style={{
                fontStyle: "normal",
                fontWeight: 500,
                letterSpacing: "0.1em",
                fontSize: "25px",
                marginLeft: 30
            }}>빠른 시일내에 준비하여 찾아뵙겠습니다.</p>

        </div>
    </div>
  );
}

export default App;
